import axios from 'axios';
import { store } from 'src/redux/store';
import { logout } from 'src/redux/userSlice';
import currentURL from '../env';

const client = axios.create({
  baseURL: currentURL?.currentEnv,
});

export const request = async ({ ...options }) => {
  if (options.token) {
    options.headers = {
      Authorization: `Bearer ${options.token}`,
    };
  }

  const onSuccess = (response) => response.data;

  const onError = (error) => {
    if (error.response) {
      console.log('Status:', error.response.status);
      console.log('Data:', error.response.data);
    } else if (error?.response?.status === 401) {
      store.dispatch(logout());
    } else {
      console.log('Error Message:', error.message);
    }

    return Promise.reject(error.response || error.message);
  };

  try {
    const response = await client(options);
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};
