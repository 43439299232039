/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
// @mui
import { TableCell, TableRow } from '@mui/material';
import moment from 'moment';
// components
// eslint-disable-next-line import/no-unresolved, import/no-extraneous-dependencies

// ----------------------------------------------------------------------

SalesRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  getOutstanding: PropTypes.func,
};

export default function SalesRow({ row, selected, getOutstanding }) {
  const { updatedAt, customer, orderItems, product, quantity, amount } = row;

  return (
    <TableRow hover selected={selected}>
      <TableCell align="left" variant="subtitle2" sx={{ cursor: 'pointer' }}>
        {customer?.salesman?.fullName}
      </TableCell>

      <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
        {customer?.name}
      </TableCell>

      <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
        {moment(updatedAt).format('DD-MM-YYYY')}
      </TableCell>

      <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
        {product?.name}
      </TableCell>

      <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
        {quantity}
      </TableCell>
      <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
        {amount}
      </TableCell>
      <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
        {customer?.storeName || '-'}
      </TableCell>
      <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
        {customer?.address || '-'}
      </TableCell>
    </TableRow>
  );
}
