import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import AxiosAll from 'src/service/axiosAll';

function ProductDetails() {
  const params = useParams();
  const [product, setProducrt] = useState({});

  // get redux state
  const user = useSelector((state) => state.user);
  const { token } = user;
  useEffect(() => {
    getProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getProduct = async () => {
    const response = await AxiosAll('get', `products/${params?.id}`, {}, token);
    const { data } = response;

    if (data?.statusCode === 200) {
      setProducrt(data?.data);
      // setLabelData(data?.data?.chart?.chartLabels);
      // setTargetArr(data?.data?.chart?.targetAmounts);
      // setAcheivedArr(data?.data?.chart?.achievedAmounts)
    }
  };
  return (
    <Container>
      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <img src={product?.image} alt="Product" />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h4">Price - {product?.price}</Typography>
          <Typography variant="body1">Description - {product?.description}</Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ProductDetails;
