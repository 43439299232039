// scrollbar
import 'simplebar-react/dist/simplebar.min.css';

// ----------------------------------------------------------------------

// routes
// theme
import ThemeProvider from 'src/theme';
// hooks
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
// components
import MotionLazy from 'src/components/animate/motion-lazy';
import ProgressBar from 'src/components/progress-bar';
import { SettingsDrawer, SettingsProvider } from 'src/components/settings';
// auth
import RouterProvider from './routes';

// ----------------------------------------------------------------------

export default function App() {
  // const user = useSelector((state) => state.user);

  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (!user) {
  //     return navigate('/login');
  //   }

  //   return navigate('/dashboard');
  // }, [user, navigate]);

  useScrollToTop();

  return (
    <SettingsProvider
      defaultSettings={{
        themeMode: 'light', // 'light' | 'dark'
        themeDirection: 'ltr', //  'rtl' | 'ltr'
        themeContrast: 'default', // 'default' | 'bold'
        themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
        themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
        themeStretch: true,
      }}
    >
      <ThemeProvider>
        <MotionLazy>
          <SettingsDrawer />
          <ProgressBar />
          <RouterProvider />
        </MotionLazy>
      </ThemeProvider>
    </SettingsProvider>
  );
}
