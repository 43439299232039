import { InputAdornment, Stack, TextField } from '@mui/material';
import PropTypes from 'prop-types';
// components
import { useMemo } from 'react';
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-unresolved
import Iconify from 'src/components/iconify';
// eslint-disable-next-line import/no-extraneous-dependencies
import debouce from 'lodash.debounce';

// ----------------------------------------------------------------------

UserTableToolbar.propTypes = {
  setQuery: PropTypes.func,
  filterTitle: PropTypes.string,
  title: PropTypes.string,
};

export default function UserTableToolbar({ filterTitle, setQuery, title = 'Search User' }) {
  const handleChange = (e) => {
    setQuery(e.target.value.trim());
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedResults = useMemo(() => debouce(handleChange, 500), []);

  return (
    <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ py: 0.5 }}>
      <TextField
        fullWidth
        value={filterTitle}
        id="outlined-basic1"
        label={title}
        variant="outlined"
        onChange={debouncedResults}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon="eva:search-fill"
                sx={{ color: 'text.disabled', width: 20, height: 20 }}
              />
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
}
