function formatAmount(value) {
  // Remove any non-numeric characters
  const numericValue = value.replace(/[^\d]/g, '');

  // Split the numeric value into integer and fractional parts
  const [integerPart, fractionalPart] = numericValue.split('.');

  // Format the integer part with commas as thousands separators
  let formattedValue = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Combine the formatted integer part and fractional part
  if (fractionalPart !== undefined) {
    formattedValue += `.${fractionalPart}`;
  }

  // Truncate the formatted value if its length (excluding commas) exceeds 10 characters
  const lengthWithoutCommas = formattedValue.replace(/,/g, '').length;
  if (lengthWithoutCommas > 10) {
    formattedValue = formattedValue.substring(0, 10 + formattedValue.indexOf(','));
  }

  return formattedValue;
}

export default formatAmount;
