/* eslint-disable no-unneeded-ternary */
export const timStampToDate = (timestamp) => {
  const convertingToDate = new Date(Number(timestamp));
  const month = convertingToDate.getMonth();
  const updatedMonth = month + 1 < 10 ? `0${month + 1}` : month + 1;
  const date = `${convertingToDate.getDate()}-${updatedMonth}-${convertingToDate.getFullYear()}`;
  return date;
};

export const formatDateTime = (dateTime) => {
  const timestamp = Number(dateTime);
  const date = new Date(timestamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const ampm = hours >= 12 ? 'PM' : 'AM';
  const hours12 = hours % 12 || 12;
  const formattedTime = `${hours12}:${minutes.toString().padStart(2, '0')} ${ampm}`;

  return formattedTime;
};

export const formatTime = (duration) => {
  if (Math.floor(duration / 60)) {
    const minutes = Math.floor(duration / 60);
    const seconds = (duration % 60).toString().padStart(2, '0');

    const formattedDuration = `${minutes}m ${seconds}s`;
    return formattedDuration;
  }
  return `${duration} sec`;
};
export const formatIndianRupees = (number) => {
  // Convert the number to a string
  number = number?.toString();
  // Use a regular expression to add commas for thousands, lakhs, crores, etc.
  number = number?.replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ',');

  // Add the Rupee symbol (₹) at the beginning
  number = `₹   ${number ? number : 0}`;

  return number;
};
export const weeklyDropdown = [
  {
    id: 1,
    title: 'Last 7 Days',
  },
  {
    id: 2,
    title: 'Last 30 Days',
  },
  {
    id: 3,
    title: 'Last 90 Days',
  },
];

export const changedToDate = (toFormat) => {
  const inputDateString = toFormat;
  const date = new Date(inputDateString);

  const day = date.getUTCDate().toString().padStart(2, '0');
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const year = date.getUTCFullYear();

  const formattedDate = `${day}-${month}-${year}`;

  return formattedDate;
};

export const changedToTime = (toFormat) => {
  const inputDateString = toFormat;
  const utcDate = new Date(inputDateString);

  // Offset for IST (Indian Standard Time) in minutes (UTC+5:30)
  const istOffset = 330;

  // Calculate the time in IST
  const istDate = new Date(utcDate.getTime() + istOffset * 60000);

  const hours = istDate.getUTCHours();
  const minutes = istDate.getUTCMinutes();

  // Determine whether it's AM or PM based on the hour
  const ampm = hours >= 12 ? 'PM' : 'AM';

  // Convert hours from 24-hour format to 12-hour format
  const formattedHours = hours % 12 || 12;

  const formattedTime = `${formattedHours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')} ${ampm}`;

  return formattedTime;
};
