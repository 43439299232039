import { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { LoadingScreen } from 'src/components/loading-screen';
import DashboardLayout from 'src/layouts/dashboard/layout';
import NotFoundPage from 'src/pages/404';
import Stocks from 'src/pages/dashboard/Stocks';
import UserReport from 'src/pages/dashboard/graph';
import Orders from 'src/pages/dashboard/orders';
import ProductDetails from 'src/pages/dashboard/productDetails';
import Samples from 'src/pages/dashboard/samples';
import SummaryDetail from 'src/pages/dashboard/summaryDetail';
import VisitDetail from 'src/pages/dashboard/visitDetail';
import Customer from 'src/pages/dashboard/customer';
import CustomerDetails from 'src/pages/dashboard/customerDetail';

const CallLogs = lazy(() => import('src/pages/dashboard/call-logs'));
const Report = lazy(() => import('src/pages/dashboard/report'));
const Summary = lazy(() => import('src/pages/dashboard/summary'));
const Dashboard = lazy(() => import('src/pages/dashboard/user'));
const Outstanding = lazy(() => import('src/pages/dashboard/outStanding'));
const Products = lazy(() => import('src/pages/dashboard/products'));
// const Customer = lazy(()=> import('src/pages/dashboard/customer'));

export default function AuthRoutes() {
  const { user } = useSelector((state) => state.user);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        }
      >
        {user.roleId === 3 ? (
          <Route path="dashboard/outstanding" element={<Outstanding />} />
        ) : (
          <>
            <Route path="dashboard/user" element={<Dashboard />} />
            <Route path="dashboard/user/:id" element={<UserReport />} />
            <Route path="dashboard/logs" element={<CallLogs />} />
            <Route path="dashboard/summary" element={<Summary />} />
            <Route path="dashboard/report" element={<Report />} />
            <Route path="dashboard/report/:id" element={<VisitDetail />} />
            <Route path="dashboard/summary/:id" element={<SummaryDetail />} />
            <Route path="dashboard/outstanding" element={<Outstanding />} />
            <Route path="dashboard/products" element={<Products />} />
            <Route path="dashboard/product/:id" element={<ProductDetails />} />
            <Route path="dashboard/stocks" element={<Stocks />} />
            <Route path="dashboard/sales" element={<Orders />} />
            <Route path="dashboard/samples" element={<Samples />} />
            <Route path="dashboard/customer" element={<Customer />} />
            <Route path="dashboard/customer/:id" element={<CustomerDetails/>} />

            <Route path="*" element={<Dashboard />} />
            <Route path="/" element={<Navigate to="dashboard/user" replace />} />
          </>
        )}
      </Route>
    </Routes>
  );
}
