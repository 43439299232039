/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
// @mui
import { TableCell, TableRow } from '@mui/material';
import moment from 'moment';
// components
// eslint-disable-next-line import/no-unresolved, import/no-extraneous-dependencies

// ----------------------------------------------------------------------

StockRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  getOutstanding: PropTypes.func,
};

export default function StockRow({ row, selected, getOutstanding }) {
  const { updatedAt, customers, products, quantity } = row;

  return (
    <TableRow hover selected={selected}>
      <TableCell
        align="left"
        variant="subtitle2"
        sx={{ cursor: 'pointer', textTransform: 'capitalize' }}
      >
        {customers?.salesman?.fullName || '-'}
      </TableCell>

      <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
        {customers?.name || '-'}
      </TableCell>

      <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
        {updatedAt ? moment(updatedAt).format('DD-MM-YYYY') : '-'}
      </TableCell>

      <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
        {products?.name || '-'}
      </TableCell>

      <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
        {quantity || '-'}
      </TableCell>
      <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
        {customers?.storeName || '-'}
      </TableCell>
      <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
        {customers?.address || '-'}
      </TableCell>
    </TableRow>
  );
}
